<template>
  <travio-modal width='450' height="250" zIndex="99999">
    <h3 slot="header">{{title}}</h3>
    <div slot="body">
      <div class="vx-row">
        <div class="vx-col w-full">
          <label class="">Normalised Name</label>
          <vs-input class="w-full" v-model="normalisedNameInEdit" v-validate="'required|max:50'" name="Normalised Name" />
          <span class="text-danger text-sm">{{ errors.first('Normalised Name') }}</span>
        </div>
        <div class="vx-col w-full mt-4">
          <label class="">Country Code</label>
          <vs-input class="w-full" v-model="countryCodeInEdit" v-validate="'required|min:2|max:2|validCountryCode'" name="Country Code" />
          <span class="text-danger text-sm">{{ errors.first('Country Code') }}</span>
        </div>
        <div class="vx-col w-full mt-4">
          <label class="">Original Name</label>
          <vs-input class="w-full" v-model="originalNameInEdit" v-validate="'required|max:50'" name="Original Name" />
          <span class="text-danger text-sm">{{ errors.first('Original Name') }}</span>
        </div>
      </div>
    </div>
    
    <div slot="footer" class="flex flex-wrap justify-end">
      <vs-button class="" color="danger" @click="close" type="filled">Cancel</vs-button>      
      <vs-button class="ml-2" color="primary" @click="saveLocation" type="filled">Save</vs-button>      
    </div>
  </travio-modal>
</template>

<script>
import TravioModal from '@/components/travio-pro/TravioModal.vue'
export default {
  components: {
    TravioModal
  },
  data () {
    return {
      normalisedNameInEdit: null,
      countryCodeInEdit: null,
      originalNameInEdit: null,
    }
  },
  props: {
    locationNormalisation: { required: true, type: Object }, 
    countryCodes: { required: true, type: Array }, 
  },
  computed: {
    title() {
      return this.locationListId ? 'Rename Location List' : 'Add Location List'
    }
  },
  created () {
    this.$validator.extend("validCountryCode", {
      getMessage: (field) =>
        "Country code must be a valid country code",
      validate: (value) => {
        // Do not validate yet until code is 2 chars
        if (!value || !(value.length == 2)) {
          return true
        }
        const country = this.countryCodes.find(x => x.toLowerCase() === value.toLowerCase())
        return country ? true : false;
      },
    });
  },
  mounted (){
    if (this.locationNormalisation.id) {
      this.normalisedNameInEdit = this.locationNormalisation.normalisedName
      this.countryCodeInEdit = this.locationNormalisation.countryCode
    }
  },
  methods: {
    async saveLocation () {
      const validationResult = await this.$validator.validateAll()
      if (!validationResult) {
        this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        return
      }
      this.$emit('onAdd', {
        normalisedName: this.normalisedNameInEdit,
        countryCode: this.countryCodeInEdit,
        originalName: this.originalNameInEdit,
      })

    },
    close () {
      this.$emit('onCancel')
    }
  }
}
</script>

<style>

</style>