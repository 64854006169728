<template>
  <div>
    <feather-icon title="Edit" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="onEdit" />
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  components: {
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    onEdit () {
      this.params.context.componentParent.editLocation(this.params.data) 
    }
  }
}
</script>
