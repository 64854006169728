<template>
  <travio-center-container grid-width='1/2'>
    <h2 style="color:#636363" class="mb-4">Location Normalisation List</h2>
    <!-- <p v-html="infoLink" class="mt-2 mb-4"></p> -->
    <vx-card>
      <div class="vx-row">
        <div class="vx-col w-full mt-4">
          <vs-button @click="addLocationList" class="mr-4 sm:mb-0 mb-2">Add</vs-button>
          <vs-input class="float-right sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-2" v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..."  />
        </div>
      </div>

      <ag-grid-vue
        ref="agGridTable"
        :components="cellRendererComponents"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="locationLists"
        :gridOptions="gridOptions"
        :autoGroupColumnDef="autoGroupColumnDef"
        :pagination="false"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :context="context"
        @first-data-rendered="onFirstDataRendered"
      >
      </ag-grid-vue>

        <div  class="flex flex-wrap justify-start mt-4">
          <vs-button
            title="Save newly added location normalisations" 
            @click="saveNewItems" :disabled="newLocationList.length == 0" 
            class="mr-4 sm:mb-0 mb-2">Save All</vs-button>
          <div class="p-2">
            <span style="color:rgba(var(--vs-primary), 1)">{{pendingSaveVerbiage}}</span>
          </div>
          
        </div>
    </vx-card>
    
    <location-normalisation-add-modal
      v-if="showEditor"
      :locationNormalisation="currentLocationInEdit"
      :countryCodes="countryCodes"
      @onAdd="onNewItemAdded" 
      @onCancel="showEditor=false" 
    />
  
  </travio-center-container>
</template>

<script>
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererActions from './cell-renderers/CellRendererActions.vue'
import LocationNormalisationAddModal from './components/LocationNormalisationAddModal.vue'
import Vue from "vue"

export default {
  components: {
    AgGridVue,
    CellRendererActions,
    LocationNormalisationAddModal
  },
  props: {
  },
  data () {
    return {
      locationLists: [],
      autoGroupColumnDef: null,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererActions
      },
      showEditor: false,
      currentLocationInEdit: {},
      newLocationList: [],
      searchQuery: '',
      context: null,
      countryCodes: []
    }
  },
  computed: {
    pendingSaveVerbiage() {
      if(this.newLocationList && this.newLocationList.length == 0)
        return ''

      return `${this.newLocationList.length} item(s) added, click Save All to save.`
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    }
  },
  async created () {
    this.$vs.loading()
    try {
      const response = await this.$http.get(`api/admin/locationNormalisation`)
      this.locationLists = response.data.resultList
      this.countryCodes = response.data.countryCodes

    } catch (error) {
      this.$_notifyFailure(error)
    }

    this.$vs.loading.close();

  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      // { headerName: 'Actions', width: 120,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererLocationListActions) },
      { field: 'id', hide: true},
      { 
        headerName: 'Normalised Name',
        field: 'normalisedName',
        width: 300,
        suppressSizeToFit: false 
      },
      { headerName: 'Country Code', field: 'countryCode', width: 150,  suppressSizeToFit: false },
      { headerName: 'Items', field: 'itemCount', width: 120,  suppressSizeToFit: false },
      { headerName: 'Actions', sortable: true, suppressSizeToFit: true, width: 120, cellRendererFramework: Vue.extend(CellRendererActions) },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 60,
      sortable: true,
      resizable: true,
      suppressMenu: true
    }

    this.autoGroupColumnDef = { minWidth: 200 };
    
    //This will make methods from this accessible to cell renderer
    this.context = { componentParent: this }

  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },

  methods: {
    addLocationList () {
      this.showEditor = true
      this.currentLocationInEdit = {
        id: null,
        normalisedName: null,
        countryCode: null
      }
    },
    onNewItemAdded (evt) {
      const existingItem = this.locationLists.find(x => 
        x.normalisedName == evt.normalisedName && x.countryCode == evt.countryCode)

      if(existingItem) {
        existingItem.itemCount+=1
      } else {
        this.locationLists.push(Object.assign({}, evt, { id: 0, itemCount: 1}))
      }

      this.locationLists = [...this.locationLists]
      this.newLocationList.push(evt)
      this.showEditor = false
    },
    async saveNewItems() {

      this.$vs.loading()

      try {
        const response = await this.$http.post(
          `api/admin/locationNormalisation/bulk`, 
          { newItems: this.newLocationList }
        )
        this.$_notifySuccess('Successfully saved new items.');
        this.locationLists = response.data.resultList
        this.newLocationList = []
      } catch (error) {
        this.$_notifyFailureByResponseData(error.response.data)
      }

      this.$vs.loading.close();
    },
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    editLocation(data){
      if(this.newLocationList.length > 0) {
        this.$_notifyFailure("There are pending changes, please save before editing existing locations.")
        return
      }

      this.$router.push({
        name: "localisation-normalisation-edit",
        params: { 
          countryCode: data.countryCode,
          normalisedName: data.normalisedName
        },
      });
    }
  }
}
</script>

<style>

</style>
